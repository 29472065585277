/* Global CSS */
html{
    box-sizing: border-box;
}
*, *:before, *:after{
    box-sizing: inherit;
}
body{
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing:"Roboto", sans-serif;
    -moz-osx-font-smoothing:"Roboto", sans-serif;
    font-style: normal;
    background-color: #080322;
}
h1,
h2,
h3,
h4,
h5,
h6,
p{
    margin: 0;
    color: #000000
}

:root{
    --primary:#56ff08;
    --bg-shade: #040112;
    --github: #e62872;
    --whitish-green: #e0f5df;
    --black: #000000;
    --white: #ffffff;
    --skyblue: #01a396;
}

.btn{
    font-family: 'Roboto';
    display: inline-block;
    padding:14px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
    transition: background-color 0.5s;
    -webkit-transition:background-color 0.5s;
    -moz-transition:background-color 0.5s;
    -ms-transition:background-color 0.5s;
    -o-transition:background-color 0.5s;
}

.btn-outline-primary{
    color:var(--skyblue);
    background-color: var(--white);
    border: 1px solid var(--skyblue);
}
.btn-outline-primary:hover{
    color:var(--white);
    background-color: var(--skyblue);
}
.btn-primary{
    color:var(--white);
    background-color: var(--skyblue);
    border: 1px solid var(--skyblue);
}
.btn-primary:hover{
    color:var(--skyblue);
    background-color: var(--white);
}
.btn-github{
    color: var(--white);
    background-color: var(--github);
    border: var(--github);
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
    gap:16px;
}
.btn-github:hover{
    color:var(--github);
    background-color: var(--white);
}
/* Global CSS ends here */

/* Heading 1 */
h1{
    font-size:4rem;
    font-weight: 700;
    line-height: 67px;
    color: #00ff00;
}

/* Heading 2 */
h2{
    font-size:2.5rem;
    font-weight: 700;
    line-height: 58px;
    color: #00ff00;
}
/* Heading 3 */
h3{
    font-size:1.5rem;
    font-weight: 700;
    line-height: 34px;
}
/* Paragraphs text large medium small */
/* Body 1 */
.text-lg{
    color: var(--white);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}

/* Body 2 */
.text-md{
    color: var(--whitish-green);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

/* About Us */
.section-title{
    color: var(--bg-shade);
    font-size: 21px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    align-items: flex-start;
    padding-top: 25px;
}

/* Contact Us */
.sub-title{
    color:var(--white);
    font-size:1.3rem;
    font-weight: 600;
    line-height: 24px;
}
/* Section Titles Ends */

/* Navbar style start */
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    background-color: #080322;
    box-shadow: 0px 5.333px 20px 0 rgba(167, 165, 165, 0.1);
    position: fixed;
    z-index: 1;
    top:0;
    left: 0;
    right:0;
}

.navbar-items > ul{
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap:42.667px;
    text-decoration: none;
    padding:5px
}



.navbar-items > ul > li > a {
    text-decoration: none;
}

/* NavbarContent */
.navbar-content{
    color: var(--white);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 24px;
    cursor: pointer;
}

.navbar-active-content{
    color: var(--primary);
}

/* Hero Section style */
.section-title-atua{
    color: var(--white);
    font-size: 2rem;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    align-items: flex-start;
    position:relative;
    animation: atua-title;
    animation-duration: 3s;
}

.anime{
    position:relative;
    animation: atua-title;
    animation-duration: 5s;
}
.hero-section{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 133.33px 85.333px 100.333px;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    background: url(../public/img/Atua\ Background.png) center/cover no-repeat;
}
.hero-section-content-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}
.hero-section-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21.333px;
}
.hero-section-btn {
    margin-top: 17.333px;
    position:relative;
    opacity: 0;
    animation: show;
    animation-duration: 2s;
    animation-delay: 4s;
    animation-fill-mode: forwards;
}

.hero-section-title-color{
    color: #00f7ff;
}


.hero-section-description{
    color: var(--white);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
}
.hero-section-img{
    display: flex;
}

/* look at the hero-section-img > img later */
.hero-section-img > img{
    width: 100%;
    height: 100%;
    animation: appear 6s ;
}
/* Hero section style ends */

/* Section Description */
.section-description{
    text-align: center;
    color: var(--black);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
}
/* Section Description Ends */

/* Services */
.service-section {
    display: grid;
    background: var(--white);
    padding: 133.333px 85.3px;
    align-items: center;
    gap:114.6px;
    grid-template-columns: repeat(2, 1fr);
}
.service-section-img > img{
    width:100%;
    height: 100%;

}
.card{
    background: rgb(243, 243, 245);
    padding:20px 40px;
    border-radius: 15%;
    box-shadow: 3px 9.333px 20px 0 rgba(0, 0, 0, 0.2);
    margin-top: 30px;
}
/* Services Ends */

/* About Us */
.about-section{
    display: grid;
    padding: 133.333px 85.3px;
    align-items: center;
    gap:114.6px;
    grid-template-columns: repeat(2, 1fr);
    background: rgb(243, 243, 245);
}
.about-section-img > img{
    width: 100%;
    height: 100%;
    border-radius: 25%;
}
.card-about{
    background: var(--white);
    padding:20px 40px;
    border-radius: 15%;
    box-shadow: 3px 9.333px 20px 0 rgba(0, 0, 0, 0.2);
    margin-top: 30px;
}
/* About Us Ends */

/* ContactUs */
.contact-section{
    display: flex;
    padding: 150px 85px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap:64px;
}

/* .contact-form-container{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(5, auto);
    width: 100%;
    row-gap: 32px;
} */

.container{
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 32px;
    row-gap: 32px;
}

.contact-label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:10.6px;
    flex:1 0 0;
    width:100%
}

.contact-input{
    display: flex;
    font-family: "Roboto", sans-serif;
    padding:16px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1.333px solid var(--skyblue);
    background: var(--white);
    color: #000000;
}

.choose-topic,
.message{
    padding-top: 25px;
}

.checkbox-label{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 0;
    gap:50.6px;
    flex: 1 0 0;
    justify-content: flex-start;
    color: var(--primary);
}
input[type='checkbox']{
    width: 21px;
    height: 21px;
    border: 0.15em solid var(--github);
    border-radius: 0.15em;
    transform: translateY(-0.075em)
}
/* ContactUs Ends */

/* Footer Starts */
.footer-container{
    display: flex;
    padding: 90px 85.333px;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--black);
    align-items: center;
    align-self: stretch;
    width: 100%;
}
.footer-link-container{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}


.footer-items > ul {
    list-style: none;
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    gap:42.667px;
    padding: 5px;
}

.footer-items > ul > li > a {
    text-decoration: none;
}

.footer-img-container{
    transform:none;
}
.footer-img-container :hover{
    transform:rotateY(360deg);
    transition-duration:1s;
    transition-timing-function: ease-in-out;
}

.footer-content-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    justify-content: center;
    padding-top: 15px;
}
.footer-content-container > p{
    font-size: 1.2rem;
}

.download-store{
    display: flex;
}

.download-store > img{
    margin:10px;
    width:10em;
    border:1px solid white;
    border-radius: 50px;
}

.divider{
    margin:15px 2.6px;
    padding:1.33px;
    background-color: var(--whitish-green);
    width:100%;
}
/* Footer Ends */

/* Responsive Screens */
@media screen and (max-width:900px) {

    h1{
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 47px;
    }
    h2{
        font-size:2rem;
        font-weight: 400;
        line-height: 38px;
    }
    h3{
        font-size:1.5rem;
        font-weight: 400;
        line-height: 30px;
    }
    p{
        font-size: 0.5rem;
    }
    .btn-outline-primary{
        display: none;
    }
    .hero-section{
        background: url(../public/img/Atua\ Background\ 900px.png) center/cover no-repeat;
        display: flex;
        flex-direction: column-reverse;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 50px;
    }
    .hero-section-title,
    .hero-section-description
    {
        text-align: center;
        
    }
    
    .contact-section,
    .footer-container{
        gap: 20px;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .about-section,
    .service-section,
    .footer-container,
    .footer-link-container,
    .footer-items > ul,
    .footer-content-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-items > ul{
        padding:5px
    }
    .about-section{
        gap:20px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .hero-section-title{
        font-size: 8rem;
    }
    .hero-section-content-box{
        gap:10px;
    }
    .contact-form-container{
        width: 100%;
    }
    .container{
        display: flex;
        flex-direction: column;
        gap:10px;
    }
    .checkbox-label{
        padding: 25px 0;
        gap:5px;
    }
    .download-store{
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width:750px){
    .hero-section{
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .section-description{
        text-align:center;
        color: var(--black);
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 30px;
    }

    .service-section {
        display:flex;
        flex-direction: column;
        padding-left: 15px;
        padding-right: 15px;
        align-items: center;
    }
    .about-section{
        display: flex;
        flex-direction: column;
        padding-top: 80px;
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
    .about-section-img{
        padding-bottom: 25px;
    }
    .contact-section{
        padding-left: 50px;
        padding-right: 50px;
    }
}

/* Hamburger menu */
.nav_hamburger {
    display: none;
    width: 1.87rem;
    height: 1.313rem;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top:40px;
    right: 30px;
}

.nav_hamburger_line{
    display: block;
    height: 0.188rem;
    width: 100%;
    background-color: var(--white);
    border-radius: 0.625rem;
    transition: all ease-in-out 0.2s;
}

@media screen and (max-width:900px) {
    .nav_hamburger{
        display: flex;
        z-index: 200;
    }
    /* .navbar-items{
        display: flex;
    } */
    .navbar-items{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--bg-shade);
        box-shadow: 0px 5.333px 80px 0 rgba(198, 197, 212, 0.1);
        top: -20rem;
        left:0;
        width: 100%;
        transition: all ease-in-out 0.4s;
    }
    .navbar-items ul{
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 3rem 0 0.6rem;
        z-index: -100;
    }
    .navbar-items ul li{
        text-align: center;
    }

    .navbar-items{
        z-index: -1000;
    }
    .navbar-items ul li a{
        padding:0.5rem;
    }
    .navbar-items.active{
        top:6px;
    }
    .nav_hamburger.active :nth-child(1){
        transform: rotate(45deg) translate(0.45rem, 0.1875rem);
    }
    .nav_hamburger.active :nth-child(2){
        opacity: 0;
    }
    .nav_hamburger.active :nth-child(3){
        transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
    }
    .navbar-content{
        letter-spacing: 2px;
        color: #f7f7fa;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
    }
}

@keyframes appear {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    
    100%{
        opacity: 1;
        transform: scale(1);
    }
    
}

@keyframes show {
    0%{
        opacity: 0;
        left: - 1000px;
    }

    100%{
        opacity: 1;
        left:0px;
    }
    
}

@keyframes atua-title {
    0%{
        left:-1000px;
    }

    100%{
        left:0px;
    }
    
}